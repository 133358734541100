<template>
    <b-tab title="Download Documents" :active="active" lazy>
        <b-card-group deck>
            <b-card title="Travel">
                <b-list-group>
                    <b-list-group-item>
                        <b-link @click="download('private/travel/CITF_Baggage_Expense_Reimbursement_Form.pdf')">CITF Baggage Expense Reimbursement Form</b-link>
                    </b-list-group-item>
                    <b-list-group-item>
                        <b-link @click="download('private/travel/SCT_Travel_Form.pdf')">SCT Travel Form</b-link>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
            <b-card title="Agreements">
                <b-list-group>
                    <b-list-group-item>
                        <b-link @click="download('private/agreement/Superintendent_Mentor_Agreement.pdf')">Superintendent Mentor Agreement</b-link>
                    </b-list-group-item>
                    <b-list-group-item>
                        <b-link @click="download('private/agreement/Superintendent_Trainee_Agreement.pdf')">Superintendent Trainee Agreement</b-link>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
        </b-card-group>
    </b-tab>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import download from '@/util/downloader';
import {sprintf} from 'sprintf-js';
import {errorModalOptions} from "../../../util/formatters";

@Component
export default class DownloadDocuments extends Vue {
    @Prop({type: Boolean, default: false}) active;


    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    async download(filename) {
        try {
            download('GET', sprintf('/documents/%s', filename));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }
}
</script>
<style scoped>

</style>