<template>
    <b-card no-body>
        <b-tabs small card>
            <download-documents active/>
            <b-tab title="Upload Documents" lazy v-if="activeUser.isAnAdministrator()">
                upload will be done at a later time.
            </b-tab>
        </b-tabs>
    </b-card>
</template>
<script>

import {Vue, Component} from 'vue-property-decorator';
import DownloadDocuments from '@/views/private/documents/DownloadDocuments.vue';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';

@Component({
    components: {
        DownloadDocuments
    }
})
export default class Documents extends Vue {

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    mounted() {
        this.$store.commit('setBreadcrumbs', [
            Breadcrumb.create('Administration', {name: 'adminMain'}, false),
            Breadcrumb.create('Documents', {name: 'documents'}, true)
        ]);
    }
}
</script>
<style scoped>

</style>